import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { COUNTRY } from '../../utils/environment';

const Section6 = () => (
  <div className="section-6">
    <h1>
      <FormattedMessage
        id="new.effectiveCare"
        defaultMessage="Effective, affordable care can be minutes."
      />
    </h1>
    {
        COUNTRY === 'CA' &&
        <FormattedHTMLMessage
          tagName="p"
          id="new.alwaysFree"
          defaultMessage="And your first session is <i>always <b>free.</b></i>"
        />
      }
  </div>
);

Section6.propTypes = {};

export default Section6;
