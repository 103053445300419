import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

const Section5 = () => (
  <div className="section-5">
    <FormattedHTMLMessage
      tagName="h1"
      id="new.whatIf"
      defaultMessage="<i class='regular'>What if</i> you didn’t need to make excuses anymore?"
    />
  </div>
);

Section5.propTypes = {};

export default Section5;
