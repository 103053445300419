/* eslint-disable arrow-body-style */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import logoTable from '../../../images/referral/logo-table.svg';
import { COUNTRY } from '../../../utils/environment';

const Section10 = () => {
  return (
    <div className="v2-section10">
      <div className="v2-s10-title">
        <FormattedMessage id="new.accessibleTailored" defaultMessage="Inkblot is accessible, tailored, and affordable." />
      </div>
      {COUNTRY === 'CA' &&
        <div className="v2-s10-desc">
          <FormattedMessage id="new.theBestPart" defaultMessage="And the best part? Your first session is always free until you find the right fit." />
        </div>
      }
      <div className="v2-s10-table">
        <div className="v210t-row row-1">
          <div className="v210t-col col-1">
            <FormattedMessage id="new.howWeCompare" defaultMessage="How we compare" />
          </div>
          <div className="v210t-col col-2"><FormattedMessage id="new.traditionalCounselling" defaultMessage="Traditional Counselling" /></div>
          <div className="v210t-col col-3"><img src={logoTable} alt="logo" /></div>
        </div>
        <div className="v210t-row row-2">
          <div className="v210t-col col-1"><FormattedMessage id="new.postPer30" defaultMessage="Cost per 30 minutes" /></div>
          <div className="v210t-col col-2">$60-100</div>
          <div className="v210t-col col-3">{COUNTRY === 'US' ? '$45' : '$37.50'}</div>
        </div>
        {COUNTRY === 'CA' &&
          <div className="v210t-row row-3">
            <div className="v210t-col col-1"><FormattedMessage id="new.firstSession" defaultMessage="First Session" /></div>
            <div className="v210t-col col-2"><FormattedMessage id="new.paid" defaultMessage="Paid" /></div>
            <div className="v210t-col col-3"><FormattedMessage id="new.free" defaultMessage="Free" /></div>
          </div>
        }
        <div className="v210t-row row-4">
          <div className="v210t-col col-1"><FormattedMessage id="new.accessability" defaultMessage="Accessibility" /></div>
          <div className="v210t-col col-2"><FormattedMessage id="new.inAnOffice" defaultMessage="In an office" /></div>
          <div className="v210t-col col-3"><FormattedMessage id="new.anywhereInCanada" defaultMessage="Anywhere in Canada" /></div>
        </div>
        <div className="v210t-row row-5">
          <div className="v210t-col col-1"><FormattedMessage id="new.travelTime" defaultMessage="Travel time" /></div>
          <div className="v210t-col col-2"><FormattedMessage id="new.variable" defaultMessage="Variable" /></div>
          <div className="v210t-col col-3"><FormattedMessage id="new.noTravelTime" defaultMessage="No travel time needed" /></div>
        </div>
        <div className="v210t-row row-6">
          <div className="v210t-col col-1"><FormattedMessage id="new.sessionLocation" defaultMessage="Session Location" /></div>
          <div className="v210t-col col-2"><FormattedMessage id="new.locationDependant" defaultMessage="Location dependent" /></div>
          <div className="v210t-col col-3"><FormattedMessage id="new.anywhere" defaultMessage="Anywhere" /></div>
        </div>
        <div className="v210t-row row-7">
          <div className="v210t-col col-1"><FormattedMessage id="new.sessionTime" defaultMessage="Session Time" /></div>
          <div className="v210t-col col-2"><FormattedMessage id="new.basedOnProviders" defaultMessage="Based on Provider’s chosen schedule" /></div>
          <div className="fake-shadow" />
          <div className="v210t-col col-3"><FormattedMessage id="new.chooseCareProvider" defaultMessage="Choose a Care Provider that fits your schedule" /></div>
        </div>
      </div>
    </div>
  );
};

export default Section10;
