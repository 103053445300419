import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import erin from '../../images/new/people//erin.png';
import zoe from '../../images/new/people/zoe.png';
import quote from '../../images/new/animations/orangequote.svg';
import christy from '../../images/new/people/christy.png';

const Section7 = () => (
  <div className="section-7">
    <div className="header">
      <FormattedHTMLMessage
        tagName="h1"
        id="new.careProvidersWho"
        defaultMessage="Care Providers who just <i>get you.</i>"
      />
      <FormattedHTMLMessage
        tagName="p"
        id="new.meetAFew"
        defaultMessage="Meet just a few of the professionals who work hard to help you operate at your best."
      />
    </div>
    <div className="testimonials">
      <div className="testimonials-container">
        <div>
          <img className="thumbnail" src={erin} alt="erin" />
          <p className="name">Erin Byron</p>
          <p className="title">REGISTERED PSYCHOTHERAPIST</p>
          <img className="quote" src={quote} alt="quote" />
          <FormattedHTMLMessage
            tagName="p"
            id="new.quoteOne"
            defaultMessage="“Inkblot is an amazing mechanism that helps clients all over Canada access care. Over the past 3 years, I have had the opportunity to work with clients of all ages from all walks of life and have seen many people make tremendous progress and start taking care of their mental health as they would any other need!”"
          />
        </div>
        <div>
          <img className="thumbnail" src={zoe} alt="zoe" />
          <p className="name">Zoe Klein</p>
          <p className="title">REGISTERED SOCIAL WORKER</p>
          <img className="quote" src={quote} alt="quote" />
          <FormattedHTMLMessage
            tagName="p"
            id="new.quoteTwo"
            defaultMessage="“As a counsellor working with Inkblot from its early days, I am so proud to be offering this quality of convenience and service to clients. They regularly tell me how much more relaxing it is for them to receive professional service, with flexible hours and measurable outcomes, from the comfort and safety of their own homes.”"
          />
        </div>
        <div>
          <img className="thumbnail" src={christy} alt="christy" />
          <p className="name">Christy McLeod</p>
          <p className="title">REGISTERED CLINICAL COUNSELLOR</p>
          <img className="quote" src={quote} alt="quote" />
          <FormattedHTMLMessage
            tagName="p"
            id="new.quoteThree"
            defaultMessage="“It is a joy to support clients on Inkblot. I love that they can be in the comfort of their own homes and we have the ability to connect, no matter the distance. In this season of change, clients are not alone, and I am grateful that I get to walk this journey alongside them.”"
          />
        </div>
      </div>
      {/* <div className="button">
        <button>
          <FormattedMessage
            id="new.learnMore"
            defaultMessage="Learn more about our Care Providers"
          />
        </button>
      </div> */}
    </div>
  </div>
);

Section7.propTypes = {};

export default Section7;
