import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import _ from 'lodash';
import { dropdownDataOne } from './partials/dropdownData';
import DropdownComponent from './partials/dropdownComponent';
import orangePlus from '../../images/new/animations/orangeplus.svg';
import orangeMinus from '../../images/new/animations/orangeminus.svg';

const Section4 = () => (
  <div className="section-4">
    <div className="dropdown-section">
      <h1>
        <FormattedHTMLMessage
          tagName="h2"
          id="new.weGetIt"
          defaultMessage="We get it. Making these changes <i>won't work for you.</i>"
        />
      </h1>
      {_.map(dropdownDataOne, ({ id, question, answer }) => (
        <DropdownComponent
          key={id}
          question={question}
          answer={answer}
          plus={orangePlus}
          minus={orangeMinus}
        />
      ))}
    </div>
  </div>
);

export default Section4;
