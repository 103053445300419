/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { COUNTRY } from '../../../utils/environment';

export const dropdownDataOne = [
  {
    id: 1,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.notEnoughTime"
        defaultMessage="I don't have enough time, my life is already so busy."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.googleCalendar"
          defaultMessage="Your Google Calendar is fuller than your laundry basket, and you can’t even fathom fitting in the time it it takes to get to and from a therapist’s office."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.massiveCommitment"
          defaultMessage="It seems like such a massive commitment to spend an hour talking about yourself when you already feel like there aren’t enough minutes in a day."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.timeIsValuable"
          defaultMessage="We understand your time is valuable, and that’s why we wanted to give you access to sessions via video so that you can spend your precious time getting care, rather than sitting in your car."
        />
      </div>
    ),
  },
  {
    id: 2,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.cantAffordIt"
        defaultMessage="I can't afford therapy - it's too expensive"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.budgetAlreadyTight"
          values={{ price: COUNTRY === 'US' ? '150' : '120' }}
          defaultMessage="Your budget is already tight, and the thought of spending over ${price} per hour to talk about your feelings doesn’t sit well. You’re unsure if you’re even ready for therapy, so the thought of dropping that much cash makes you feel even more anxious that you already are."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.weHearYou"
          defaultMessage="We hear you. The average cost of traditional therapy is $60-100 per 30 minutes, and if you don’t have insurance, effective treatment can really add up."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.just3750"
          values={{ price: COUNTRY === 'US' ? '45' : '37.50', firstSession: COUNTRY === 'US' ? '' : 'Your first session is also always free until you find the right fit.' }}
          defaultMessage="On Inkblot, you can see a Care Provider for just ${price} per 30 minute session. {firstSession}"
        />
      </div>
    ),
  },
  {
    id: 3,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.hateTalkingAbout"
        defaultMessage="I hate talking about myself, and thinking about my problems just makes me feel more anxious."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.introductionGames"
          defaultMessage="You’re the kind of person who dreads go-around-in-the-circle introduction games. Sharing your thoughts tends to make you feel worse and you’d rather just keep them to yourself."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.talkingAboutYourself"
          defaultMessage="Talking about yourself in therapy won’t feel like an awkward networking event. We promise."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.professionallyTrained"
          defaultMessage="Our Care Providers are professionally trained to help you discover and unpack your inner voice in a safe and judgement free space."
        />
      </div>
    ),
  },
  {
    id: 4,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.uniqueSituation"
        defaultMessage="My situation is unique, nobody could possibly understand it."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.reallyDifficult"
          defaultMessage="It’s really difficult for others to understand what you’ve been through. Life has been complicated and traumatic. You don’t feel that anyone is equipped to help."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.youreSick"
          defaultMessage="You’re sick of hearing people say they ‘understand what you’re going through and you’re only willing to open up to someone who won’t pander to you."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.onInkblot"
          defaultMessage="On Inkblot, you will be able to work with Care Providers who will use their professional training to help unpack your unique circumstances."
        />
      </div>
    ),
  },
  {
    id: 5,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.nobodyIKnow"
        defaultMessage="Nobody I know has been to therapy and they're all fine. Plus if I go, they will judge me."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.therapyIsFor"
          defaultMessage="You’ve been told ‘therapy is for people who can’t handle their own problems’ more times than you can count. If you decided to talk to someone now, you’d be seen as weak and needy."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.fullyConfidential"
          defaultMessage="Thankfully, Inkblot is fully confidential, and all your private information is kept safe, so your sessions are only between you and your Care Provider."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.talkTherapy"
          defaultMessage="When you start feeling better, we bet you’ll even want to tell your judgey aunt Karen all about the benefits of talk therapy."
        />
      </div>
    ),
  },
  {
    id: 6,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.chemicalImbalance"
        defaultMessage="I have a chemical imbalance, so only medication works for me."
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.sinceStartingTreatment"
          defaultMessage="You’ve been to your doctor and been diagnosed with a clinical mental health condition which is treatable by medication. Since starting treatment, you’re feeling so much better and therefore see no need for therapy."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.noDoubt"
          defaultMessage="There’s no doubt that the medication is helpful, and likely a big reason why you’re back on track."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.medicationCombined"
          defaultMessage="Medication combined with talk therapy is 40% more effective than either alone. Counselling helps you learn how to cope better in the long term and feel better on a day to day basis."
        />
      </div>
    ),
  },
];

export const dropdownDataTwo = [
  {
    id: 1,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.priceSoLow"
        defaultMessage="How is the price so low?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.sessionsViaVideo"
          defaultMessage="• By having the sessions via video, Inkblot reduces overhead costs like office rent & commuting for our Practitioners."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.manyInkblotProviders"
          defaultMessage="• Many Inkblot Care Providers take sessions on Inkblot to account for cancellations in their office-based schedules."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.providersUseInkblot"
          defaultMessage="• Our Providers use Inkblot as an opportunity to see clients who may not otherwise be able to see a therapist due to cost barriers."
        />
      </div>
    ),
  },
  {
    id: 2,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.doesntSeemGood"
        defaultMessage="It doesn’t seem like a good idea to talk about my private information over the internet. How can I trust this is safe?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.strictLaws"
          defaultMessage="• There are strict laws and regulations overseeing how personal & health information is captured, stored, processed and destroyed in online health service companies. Inkblot exceeds all privacy and confidentiality requirements."
        />
        {/* <FormattedHTMLMessage
          tagName="p"
          id="new.curious"
          defaultMessage="Curious to know more?"
        />
        <button style={{ color: 'rgba(18, 33, 103, 0.9)',
        border: 'solid 2px rgba(18, 33, 103, 0.9)', marginTop: '20px' }}>
          See how my data is used
        </button> */}
      </div>
    ),
  },
  {
    id: 3,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.videoSessions"
        defaultMessage="Video sessions sound fine in theory but are they really as effective as in-person sessions?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.largeClinicalStudies"
          defaultMessage="• There have been many large clinical studies showing equal effectiveness when comparing virtual counselling to in-person counselling."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.theirOwnSpace"
          defaultMessage="• Many clients feel more comfortable discussing personal matters from the security of their own space."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.greaterAccess"
          defaultMessage="• Whether due to lack of local specialized care, or disabilities, many people are limited in their care options. Virtual counselling allows greater access for all."
        />
      </div>
    ),
  },
  {
    id: 4,
    question: (
      <FormattedHTMLMessage
        tagName="p"
        id="new.hardTimeFinding"
        defaultMessage="I’ve had a hard time finding the right provider. How can I be sure that I’ll find someone who understands me?"
      />
    ),
    answer: (
      <div>
        <FormattedHTMLMessage
          tagName="p"
          id="new.removeJargon"
          defaultMessage="• We remove clinical jargon to empower you to find a care provider with an approach that you connect with."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.twentlyLanguages"
          defaultMessage="• On Inkblot we provide care in 20+ languages and over 7 religions including spirituality. We assure that you’ll feel heard and understood."
        />
        <FormattedHTMLMessage
          tagName="p"
          id="new.feedbackFromOthers"
          defaultMessage="• We use the feedback from others to ensure our providers are of the highest calibre and are providing effective care."
        />
      </div>
    ),
  },
];
