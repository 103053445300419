import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { unauthAuditProgress } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../utils/amplitude';
import section1Animation from '../../images/new/animations/section-1-animation.png';

const Section1 = () => {
  const toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: '' });
    unauthAuditProgress('', 'open_signup');
    window.scrollTo({
      top: window.pageYOffset + 1,
    });
  };

  const renderNumbersLocale = () => (
    <div className="by-the-numbers flex-row">
      <div className="stat">
        <h5>1,000,000+</h5>
        <FormattedHTMLMessage
          tagName="p"
          id="new.employees"
          defaultMessage="Employees have access to Inkblot"
        />
      </div>
      <div className="stat">
        <h5>750+</h5>
        <FormattedHTMLMessage
          tagName="p"
          id="new.physiciansHave"
          defaultMessage="Physicians have referred to Inkblot"
        />
      </div>
      <div className="stat">
        <h5>950+</h5>
        <FormattedHTMLMessage
          tagName="p"
          id="new.trustedPractitioners"
          defaultMessage="Trusted practitioners to choose from"
        />
      </div>
    </div>
  );

  return (
    <div className="section-1">
      <div className="flex-row">
        <div className="half-column">
          <div className="hero">
            <h1>
              <FormattedMessage
                id="new.therapyDoesnt"
                defaultMessage="Mental health support doesn’t have to be expensive, intimidating, or impossible to access."
              />
            </h1>
            <FormattedHTMLMessage
              tagName="p"
              id="new.beatTheStigma"
              defaultMessage="<b><i>Beat the stigma</i></b> and receive affordable support, without long waits, from the comfort of your own home."
            />
            <button onClick={toggleSignUp}>
              <FormattedMessage
                id="new.feelingBetter"
                defaultMessage="Start Feeling Better"
              />
            </button>
            {renderNumbersLocale()}
          </div>
        </div>
        <div className="half-column">
          <img src={section1Animation} alt="Canadian video counselling" />
        </div>
      </div>
    </div>
  );
};

Section1.propTypes = {};

export default Section1;
