/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import step3 from '../../../images/referral/step-3.png';
import french3 from '../../../images/custom/screens/group-1.svg';
import ReferralSteps from './ReferralSteps';
import { COUNTRY } from '../../../utils/environment';

const featureThree = {
  stepId: 'new.stepThree',
  step: 'STEP THREE',
  id: 'eap.firstFree',
  titleValue: COUNTRY === 'CA' ? 'free' : '',
  title: 'Book your first {free} Session',
  descriptionId: 'eap.foundProvider',
  descriptionValue: COUNTRY === 'CA' ? 'Your first session is always free until you find the right provider.' : '',
  description: 'When you’ve found your provider, you’ll be able to book your initial session. {free}',
  image: step3,
  frImage: french3,
};

const Section6 = props => {
  const {
    stepId,
    step,
    id,
    titleValue,
    title,
    descriptionValue,
    description,
    descriptionId,
    image,
    frImage,
  } = featureThree;
  return (
    <div className="v2-section6">
      <div className="v2-s6-text">
        <div className="text">
          <div className="step-number font-open-sans-blue-16">{props.intl.formatMessage({ id: stepId, defaultMessage: step })}</div>
          <div className="step-title font-barlow-34">
            <FormattedHTMLMessage
              tagName="span"
              id={id}
              values={{ free: titleValue }}
              defaultMessage={title}
            />
          </div>
          <div className="step-description font-open-sans-20">
            <FormattedHTMLMessage
              tagName="span"
              id={descriptionId}
              values={{ free: descriptionValue }}
              defaultMessage={description}
            />
          </div>
        </div>
        <div className="steps">
          <ReferralSteps currentStep={3} />
        </div>
      </div>
      <div className="v2-s6-image">
        <div className="bg-url-image" style={{ backgroundImage: `url(${localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage})` }} />
        <img className="url-image" src={localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage} alt={title} />
      </div>
    </div>
  );
};

Section6.propTypes = {
  intl: PropTypes.object,
};

Section6.defaultProps = {
  intl: {},
};

export default injectIntl(Section6);
