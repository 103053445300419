import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DropdownComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const {
      question,
      answer,
      plus,
      minus,
    } = this.props;
    const { open } = this.state;

    return (
      <div className="faq">
        <div className="question" role="presentation" onClick={this.handleClick}>
          <img src={open ? minus : plus} alt="plus" />
          {question}
        </div>
        {open ? <p className="answer" role="presentation" onClick={this.handleClick}>{answer}</p> : null}
      </div>
    );
  }
}

DropdownComponent.propTypes = {
  answer: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  plus: PropTypes.string.isRequired,
  minus: PropTypes.string.isRequired,
};

export default DropdownComponent;
