import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import inkblotLogo from '../../images/new/logos/inkblot-logo.svg';
import timeDivider1 from '../../images/new/animations/time-divider-1.svg';
import timeDivider2 from '../../images/new/animations/time-divider-2.svg';
import timeDivider3 from '../../images/new/animations/time-divider-3.svg';

const Section3 = () => (
  <div className="section-3">
    <h1><FormattedMessage
      id="new.changeStory"
      defaultMessage="It’s time to change your story."
    />
    </h1>
    <div className="flex-row margin-bottom">
      <div className="half-column">
        <h2>
          <FormattedMessage
            id="new.youAlone"
            defaultMessage="You alone vs. Life"
          />
        </h2>
      </div>
      <div className="half-column">
        <h2>
          <FormattedMessage
            id="new.youWithInkblot"
            defaultMessage="You with"
          />
        </h2>
        <img src={inkblotLogo} alt="Inkblot logo" />
      </div>
    </div>
    <div className="day-in-the-life">
      <div className="flex-row">
        <div className="grey-box">
          <FormattedHTMLMessage
            tagName="p"
            id="new.youAloneOne"
            defaultMessage="<b>After a night of tossing and turning</b> you sleep past your alarm and rush downstairs. You asked your partner
            if they could tidy up the kitchen before work but they forgot."
          />
          <FormattedHTMLMessage
            tagName="h2"
            id="new.youFeelStrained"
            defaultMessage="You feel <span class='h2-span'>strained</span> trying to communicate with them."
          />
        </div>
        <div className="time">
          <h5>8:00 AM</h5>
          <img src={timeDivider1} alt="time" />
        </div>
        <div className="white-box">
          <FormattedHTMLMessage
            tagName="p"
            id="new.youWithInkblotOne"
            defaultMessage="After a great nights sleep, you sit on your couch and open up your laptop. <b>You connect with your therapist via video on Inkblot</b> and discuss how things are going at home and at work. After the session, <b>you tell your partner you appreciate them</b> and"
          />
          <FormattedHTMLMessage
            tagName="h2"
            id="new.youFeelReady"
            defaultMessage="you feel <span class='h2-span'>ready</span> to take on the day."
          />
        </div>
      </div>
      <div className="flex-row">
        <div className="grey-box">
          <FormattedHTMLMessage
            tagName="p"
            id="new.youAloneTwo"
            defaultMessage="Driving to work you get stuck in horrible traffic. <b>You’re feeling so stressed out.</b> Walking to your desk an hour late,"
          />
          <FormattedHTMLMessage
            tagName="h2"
            id="new.youSenseJudgement"
            defaultMessage="you sense the <span class='h2-span'>judgement</span> in your colleague&apos;s stares."
          />
        </div>
        <div className="time">
          <h5>11:00 AM</h5>
          <img src={timeDivider2} alt="time" />
        </div>
        <div className="white-box">
          <FormattedHTMLMessage
            tagName="p"
            id="new.youWithInkblotTwo"
            defaultMessage="Listening to your favourite podcast on the way to work, you hardly notice being stuck in traffic. <b>You arrive at work late,</b>"
          />
          <FormattedHTMLMessage
            tagName="h2"
            id="new.youFeelConfident"
            defaultMessage="but feel <span class='h2-span'>confident</span> you&apos;ll be able to tackle everything."
          />
        </div>
      </div>
      <div className="flex-row">
        <div className="grey-box">
          <FormattedHTMLMessage
            tagName="p"
            id="new.youAloneThree"
            defaultMessage="The work day ends and you arrive home to a huge mess in the kitchen. Your partner has been cooking and didn’t clean up. <b>You lash out at them and start screaming about
            how they never help when it matters.</b>"
          />
          <FormattedHTMLMessage
            tagName="h2"
            id="new.youStormUpstairs"
            defaultMessage="You storm upstairs, feeling a mix of <span class='h2-span display-inline'> anger, disappointment and sadness.</span>"
          />
        </div>
        <div className="time">
          <h5>6:30 PM</h5>
          <img src={timeDivider3} alt="time" />
        </div>
        <div className="white-box">
          <FormattedHTMLMessage
            tagName="p"
            id="new.youWithInkblotThree"
            defaultMessage="After work you get home to see that your partner made a wonderful meal but left a huge mess. <b>You give them a wink and say you’ll handle the dishes as a thank you for dinner.</b>"
          />
          <FormattedHTMLMessage
            tagName="h2"
            id="new.youreSoRelieved"
            defaultMessage="You’re so <span class='h2-span'>relieved</span> that you’ve learned how to handle your stress without lashing out."
          />
        </div>
      </div>
      <div className="flex-row">
        <div className="grey-box">
          <FormattedHTMLMessage
            tagName="p"
            id="new.youAloneFour"
            defaultMessage="<b>All you want to do is rest,</b> but"
          />
          <FormattedHTMLMessage
            tagName="h2"
            id="new.brainFlooded"
            defaultMessage="your brain is flooded with <span class='h2-span'>guilt</span> from your actions earlier."
          />
          <FormattedHTMLMessage
            tagName="p"
            id="new.youAloneFive"
            defaultMessage="You feel your eyes get heavier. You pass out, and wake up the next day to <b>All you want to do is rest,</b> repeat a similar process."
          />
        </div>
        <div className="time">
          <h5>11:00 PM</h5>
        </div>
        <div className="white-box">
          <FormattedHTMLMessage
            tagName="p"
            id="new.youWithInkblotFour"
            defaultMessage="Although today had it&apos;s hardships,"
          />
          <FormattedHTMLMessage
            tagName="h2"
            id="new.youFeltPrepared"
            defaultMessage="you felt prepared to <span class='h2-span'>cope</span> with them effectively."
          />
          <FormattedHTMLMessage
            tagName="p"
            id="new.youWithInkblotFive"
            defaultMessage="You feel your eyes get heavier. You fall soundly asleep and wake up the next day <b>ready to face life&apos;s challenges.</b>"
          />
        </div>
      </div>
    </div>
  </div>
);

export default Section3;
