/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import checkboxOn from '../../../images/referral/checkbox-on.svg';
import checkboxOff from '../../../images/referral/checkbox-off.svg';
import { COUNTRY } from '../../../utils/environment';

const ReferralSteps = ({ currentStep, intl }) => {
  const steps = [
    <p>{intl.formatMessage({ id: 'new.firstStep', defaultMessage: 'Complete our matching survey.' })}</p>,
    <p>{intl.formatMessage({ id: 'new.secondStep', defaultMessage: 'Select your best-suited practitioner.' })}</p>,
    <FormattedHTMLMessage
      tagName="p"
      id="new.thirdStep"
      values={{ firstSession: COUNTRY === 'US' ? 'session' : 'free session' }}
      defaultMessage="Book your first {firstSession}."
    />,
    <FormattedHTMLMessage tagName="p" id="new.fourthStep" defaultMessage="Receive care and <i>feel better." />,
  ];
  return (
    <div className="v2-steps">
      {
        steps.map((step, index) => (
          <React.Fragment key={`key-${index}`}>
            <div className="step-item" key={`key-${index}`}>
              <div
                className="si-checkbox"
                style={{ backgroundImage: `url(${currentStep - 1 >= index ? checkboxOn : checkboxOff})` }}
              />
              <div className={`si-text ${currentStep - 1 === index ? 'si-text-bold' : ''}`}>
                {step}
              </div>
            </div>
            {steps.length - 1 !== index && (
              <div className="step-vertical-line">
                <div className="svl-1" /><div className="svl-2" />
              </div>
            )}
          </React.Fragment>
        ))
      }
    </div>
  );
};

ReferralSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ReferralSteps);
