import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

const Section8 = () => (
  <div className="section-8">
    <h1>
      <FormattedHTMLMessage
        id="new.ourMission"
        defaultMessage="It’s our mission to provide you with the most <span class='regular'>effective</span>care possible."
      />
    </h1>
  </div>
);

Section8.propTypes = {};

export default Section8;
