/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import step1 from '../../../images/referral/step-1.svg';
import ReferralSteps from './ReferralSteps';
import french1 from '../../../images/custom/screens/Group8.png';

const featureOne =
  {
    stepId: 'new.stepOne',
    step: 'STEP ONE',
    id: 'eap.shortQuestionnaire',
    title: 'Short Questionnaire',
    description: 'Fill out our matching survey to help you connect to a provider best suited to your needs. You can match based on your symptoms, stressors, and even language and religion.',
    descriptionId: 'eap.fillOut',
    image: step1,
    frImage: french1,
  };

const Section4 = props => {
  const {
    stepId, step, id, title, description, descriptionId, image, frImage,
  } = featureOne;
  return (
    <div className="v2-section4">
      <div className="v2-s4-text">
        <div className="text">
          <div className="step-number font-open-sans-blue-16">{props.intl.formatMessage({ id: stepId, defaultMessage: step })}</div>
          <div className="step-title font-barlow-34"><span>{props.intl.formatMessage({ id, defaultMessage: title })}</span></div>
          <div className="step-description font-open-sans-20">{props.intl.formatMessage({ id: descriptionId, defaultMessage: description })}</div>
        </div>
        <div className="steps">
          <ReferralSteps currentStep={1} />
        </div>
      </div>
      <div className="v2-s4-image">
        <div className="bg-url-image" style={{ backgroundImage: `url(${localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage})` }} />
        <img className="url-image" src={localStorage.getItem('selectedLanguage') !== 'fr' ? image : frImage} alt={title} />
      </div>
    </div>
  );
};

Section4.propTypes = {
  intl: PropTypes.object,
};

Section4.defaultProps = {
  intl: {},
};

export default injectIntl(Section4);
