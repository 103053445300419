import React from 'react';
import { FormattedMessage } from 'react-intl';
import { unauthAuditProgress } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../utils/amplitude';
import section11Animation from '../../images/new/animations/section-11-animation.png';

const Section11 = () => {
  const toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: '' });
    unauthAuditProgress('', 'open_signup');
    window.scrollTo({
      top: window.pageYOffset + 1,
    });
  };
  return (
    <div className="section-11">
      <div className="flex-row">
        <div className="half-column">
          <div className="hero">
            <h1>
              <FormattedMessage
                id="new.accessingCare"
                defaultMessage="Accessing the care you need has never been easier."
              />
            </h1>
            <button onClick={toggleSignUp}>
              <FormattedMessage
                id="new.feelingBetter"
                defaultMessage="Start Feeling Better"
              />
            </button>
          </div>
        </div>
        <div className="half-column">
          <img src={section11Animation} alt="Canadian video counselling" />
        </div>
      </div>
    </div>
  );
};

Section11.propTypes = {};

export default Section11;
